import React from 'react'
import axios from 'axios'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import ContactForm from '../../components/form'

export default class TeamMember extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    const pageFullPath = this.props.location.pathname
    const teamMemberId = pageFullPath.substring(
      pageFullPath.lastIndexOf('/') + 1
    )

    axios
      .get(`${process.env.API_URL}/umbraco/api/team/get?url=${teamMemberId}`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => {})
  }


  render() {
    const { data } = this.state
    let settings = {}

    let name = ''
    let title = ''
    let bio = ''
    let photo = ''
    let email = ''

    if (data) {
      name = data.Name
      title = data.Title
      bio = data.Bio

      if (data.Photo) {
        photo = <img src={`${process.env.API_URL}${data.Photo}`} />
      } else {
        photo = (
          <StaticQuery
            query={graphql`
              query {
                mainlogo: file(
                  relativePath: { eq: "profile-placeholder.png" }
                ) {
                  childImageSharp {
                    fixed(width: 262) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            `}
            render={data => (
              <Img
                fixed={data.mainlogo.childImageSharp.fixed}
                title="Em Laços"
                alt="Em Laços"
              />
            )}
          />
        )
      }

      email = data.Email
      settings = {
        showDate: true,
        showPhone: true,
        target: data.Id,
      }
    }

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Equipa</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section id="about" className="about_us section-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-12 col-xs-12">
                <div className="about_content">
                  <h1>{name}</h1>
                  <h3>{title}</h3>
                  {/* <span className="separator"></span> */}
                  <span dangerouslySetInnerHTML={{ __html: bio }} />
                </div>
                <div className="mbtop">
                  <b>Email: </b>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="team-slide">{photo}</div>
              </div>
            </div>
            <div className="row mbtop">
              <div className="section-title text-center wow zoomIn">
                <h2>Contacte-nos</h2>
                <span />
              </div>
              <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
                <ContactForm {...settings} />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
